import React from 'react';
import "../../src/App.css";

const Card = ({ title, pastEvents, hasTechTools, maxCapacity }) => {
  const isBooked = pastEvents && pastEvents.pastEvents && pastEvents.pastEvents.length > 0;

  const cardStyle = {
    backgroundColor: isBooked ? 'red' : 'lightgreen', // Set background color based on isBooked
    border: '2px solid #fff',
    borderRadius: '8px',
    padding: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    //font: '16px Arial, sans-serif',
  };

  const statusStyle = {
    fontWeight: 'bold',
    padding: '5px',
    borderRadius: '5px',
  };

  return (
    <div className="card" style={cardStyle}>
      <h2 style={{ fontWeight: '400', marginBottom: '5px', color: 'navy', fontSize: '1.75em' }}>{title}</h2>
      <div>
        {pastEvents ? (
          <p style={{ margin: '0' }}>
            <strong>Status: <span style={{ ...statusStyle }}>{isBooked ? 'Booked ⛔️' : 'Free ✅'}</span></strong>
          </p>
        ) : (
          <p><strong>Status: No past events</strong></p>
        )}
        {hasTechTools !== undefined && maxCapacity !== undefined && (
          <p>
            <strong>🖥️ Conferencing: {hasTechTools ? 'Yes' : 'No'}</strong>&nbsp; | &nbsp;
            <strong>👥 Capacity: {maxCapacity}</strong>
          </p>
        )}
      </div>
    </div>
  );
};

export default Card;