import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import CardGrid from "./components/CardGrid";
import Blackbear from "./components/Blackbear";
import Blueberry from "./components/Blueberry";
import Cloudberry from "./components/Cloudberry";
import Caribou from "./components/Caribou";
import Eagle from "./components/Eagle";
import Virtual from "./components/Virtual";
import Fox from "./components/Fox";
import Rabbit from "./components/Rabbit";
import useFetch from "./hooks/useFetch"; // Custom hook
import logo from './logo.png';
import "./App.css";

const App = () => {
  const { data: pastEventsDataForBlackbear } = useFetch("/api/calendar/c_1882nmp4ac1ncj95i267tm55o6fiq@resource.calendar.google.com/events");
  const { data: pastEventsDataForBlueberry } = useFetch("/api/calendar/c_1888uocm6v6pki55g6tsrg3le5spc@resource.calendar.google.com/events");
  const { data: pastEventsDataForCloudberry } = useFetch("/api/calendar/c_1883u543rohgkhqvla5nrqpptghus@resource.calendar.google.com/events");
  const { data: pastEventsDataForCaribou } = useFetch("/api/calendar/c_1888499b7ct1sipjkft0lhvkgnjus@resource.calendar.google.com/events");
  const { data: pastEventsDataForEagle } = useFetch("/api/calendar/c_188bplkg0qbdegcugqso26idsevu4@resource.calendar.google.com/events");
  const { data: pastEventsDataForVirtual } = useFetch("/api/calendar/c_1888pcsk2qmo4iqtm52vf0qaqprdq@resource.calendar.google.com/events");
  const { data: pastEventsDataForFox } = useFetch("/api/calendar/c_188crdfb2beskic9nc6nqkel5cuf6@resource.calendar.google.com/events");
  const { data: pastEventsDataForRabbit } = useFetch("/api/calendar/c_188c6704jcs9chc0l0pvup24isa3m@resource.calendar.google.com/events");
  const [showModal, setShowModal] = useState(false);

  const cards = [
    {
      title: "Caribou Room",
      color: "#FFB627", // Set a unique color for Caribou Room
      pastEvents: pastEventsDataForCaribou,
      hasTechTools: true, // Example: Change this based on the room's tech tools availability
      maxCapacity: 10, // Example: Change this based on the room's maximum capacity
    },
    {
      title: "Blackbear Room",
      color: "#CB3B27", // Set a unique color for Blackbear Room
      pastEvents: pastEventsDataForBlackbear,
      hasTechTools: true, // Example: Change this based on the room's tech tools availability
      maxCapacity: 8, // Example: Change this based on the room's maximum capacity
    },
    {
      title: "Virtual Facilitation",
      color: "#1e96fc", // Set a unique color for Virtual Room
      pastEvents: pastEventsDataForVirtual,
      hasTechTools: true, // Example: Change this based on the room's tech tools availability
      maxCapacity: 3, // Example: Change this based on the room's maximum capacity
    },
    {
      title: "Eagle Room",
      color: "#bfd200", // Set a unique color for Eagle Room
      pastEvents: pastEventsDataForEagle,
      hasTechTools: true, // Example: Change this based on the room's tech tools availability
      maxCapacity: 15, // Example: Change this based on the room's maximum capacity
    },
    {
      title: "Rabbit Room",
      color: "#55a630", // Set a unique color for Rabbit Room
      pastEvents: pastEventsDataForRabbit,
      hasTechTools: false, // Example: Change this based on the room's tech tools availability
      maxCapacity: 15, // Example: Change this based on the room's maximum capacity
    },
    {
      title: "Fox Room",
      color: "#B841B5", // Set a unique color for Fox Room
      pastEvents: pastEventsDataForFox,
      hasTechTools: false, // Example: Change this based on the room's tech tools availability
      maxCapacity: 4, // Example: Change this based on the room's maximum capacity
    },
    {
      title: "Cloudberry Room",
      color: "#FF9505", // Set a unique color for Cloudberry Room
      pastEvents: pastEventsDataForCloudberry,
      hasTechTools: false, // Example: Change this based on the room's tech tools availability
      maxCapacity: 2, // Example: Change this based on the room's maximum capacity
    },
    {
      title: "Blueberry Room",
      color: "#E2711D", // Set a unique color for Blueberry Room
      pastEvents: pastEventsDataForBlueberry,
      hasTechTools: false, // Example: Change this based on the room's tech tools availability
      maxCapacity: 2, // Example: Change this based on the room's maximum capacity
    },
  ];

  return (
    <Router>
      <div className="app">
        <div className="header">
          <img src={logo} alt="Logo" className="app-logo" />
        </div>
        <Routes>
          <Route path="/" element={<CardGrid cards={cards} />} />
          <Route path="/blackbear-room" element={<Blackbear pastEvents={pastEventsDataForBlackbear} style={{ height: "95vh" }} />} />
          <Route path="/blueberry-room" element={<Blueberry pastEvents={pastEventsDataForBlueberry} style={{ height: "95vh" }} />} />
          <Route path="/fox-room" element={<Fox pastEvents={pastEventsDataForFox} style={{ height: "95vh" }} />} />
          <Route path="/cloudberry-room" element={<Cloudberry pastEvents={pastEventsDataForCloudberry} style={{ height: "95vh" }} />} />
          <Route path="/caribou-room" element={<Caribou pastEvents={pastEventsDataForCaribou} style={{ height: "95vh" }} />} />
          <Route path="/eagle-room" element={<Eagle pastEvents={pastEventsDataForEagle} style={{ height: "95vh" }} />} />
          <Route path="/rabbit-room" element={<Rabbit pastEvents={pastEventsDataForRabbit} style={{ height: "95vh" }} />} />
          <Route path="/virtual-facilitation" element={<Virtual pastEvents={pastEventsDataForVirtual} style={{ height: "95vh" }} />} />
        </Routes>
        <div>
          <button onClick={() => setShowModal(true)} style={{ backgroundColor: "#B841B5", color: 'white', margin: '.5em' }}>Floor Plan</button>
          {showModal && (
            <div className="modal">
              <div className="modal-content">
                <button onClick={() => setShowModal(false)}>Close 🅧 </button>
                <img src="/FloorPlan.png" alt="Floor Plan" style={{ width: "100%", height: "80vh" }}></img>
              </div>
            </div>
          )}
          <button style={{ backgroundColor: "#EBA900" }}>
            <Link to="/" style={{ color: "white", textDecoration: "none" }}>Home</Link>
          </button>
        </div>
      </div>
    </Router>
  );
};

export default App;
